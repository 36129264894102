import { SessionTypeEnum as GcgSessionTypeEnum } from "../generated/graphql/types";
import { SessionTypeEnum } from "../graphql/types/globals.v5";
var VIRTUAL_SESSION_PLACEHOLDER_IMAGE = 'https://crehana-vercel-images-prd.imgix.net/public/b2b-admin-mf/a2117e3c-45a0-4018-bdff-1c769ea65698-placeholder_sesion-virtual-1.png?auto=format&fit=clip';
var LOCATION_SESSION_PLACEHOLDER_IMAGE = 'https://crehana-vercel-images-prd.imgix.net/public/b2b-admin-mf/91952206-9519-41a0-a181-36c19b75cd73-placeholder_sesion-presencial-1.png?auto=format&fit=clip';
export function getLiveSessionImagePlaceholder(_ref) {
  var imageUrl = _ref.imageUrl,
    eventType = _ref.eventType;
  if (imageUrl) return imageUrl;
  if (eventType === SessionTypeEnum.ONLINE || eventType === GcgSessionTypeEnum.Online) return VIRTUAL_SESSION_PLACEHOLDER_IMAGE;
  return LOCATION_SESSION_PLACEHOLDER_IMAGE;
}

// TODO: This funciton will be removed in the future when generating the new codegen with v5
export function getSessionImagePlaceholder(_ref2) {
  var imageUrl = _ref2.imageUrl,
    type = _ref2.type;
  if (imageUrl) return imageUrl;
  if (type === 'virtual') return VIRTUAL_SESSION_PLACEHOLDER_IMAGE;
  return LOCATION_SESSION_PLACEHOLDER_IMAGE;
}